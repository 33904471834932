<template>
  <div class="notification-panel" :class="{ fullscreen: fullscreen }">
    <div class="d-flex justify-content-between align-items-center p-2">
      <div>
        <button
          v-if="fullscreen"
          class="notification-close-btn btn flat circle mr-2"
          @click="$emit('close')"
        >
          <i class="fas fa-times"> </i>
        </button>
        <span class="notification-title">Notifications</span>
      </div>
      <div class="d-flex align-items-center">
        <label class="label mr-1" for="filterUnread">Unread Only</label>
        <toggle-switch
          :value="unreadOnly"
          name="filterUnread"
          @input="(val) => $emit('update:unreadOnly', val)"
        ></toggle-switch>
      </div>
    </div>
    <hr class="mb-0" />

    <div v-if="isLoading" class="notification-list px-2 py-1">
      <notification-item v-for="n in 4" :key="n" isLoading> </notification-item>
    </div>
    <div v-else class="notification-list px-2 py-1">
      <div v-if="notifications.length < 1" class="notification-empty">
        <img
          class="notification-empty__img"
          :src="emptySVG"
          alt="empty-notification"
        />
        <p class="d-block w-100 text-center">No notifications at the moment.</p>
      </div>

      <!-- Notification list with virtual scrolling -->
      <DynamicScroller v-else :items="notifications" :min-item-size="120">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            :size-dependencies="[item.body]"
          >
            <notification-item
              :notification="item"
              :hasRedirect="redirectIdentifier(item)"
              @click="(data) => $emit('item-click', data)"
            >
            </notification-item>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <InfiniteLoading @infinite="infiniteScrollHandler">
        <span slot="no-more"></span>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import emptySVG from "./empty.svg";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import InfiniteLoading from "vue-infinite-loading";

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    InfiniteLoading,
    NotificationItem: () =>
      import("@/components/GlobalComponents/Notifications/NotificationItem"),
    ToggleSwitch: () => import("@/components/GlobalComponents/ToggleSwitch")
  },
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    unreadOnly: {
      type: Boolean,
      default: true
    },
    notifications: {
      type: Array,
      default: () => []
    },
    redirectIdentifier: {
      type: Function,
      default: () => false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      emptySVG
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    infiniteScrollHandler(state) {
      this.$emit("infinite-scroll", state);
    }
  }
};
</script>

<style lang="scss">
.notification-panel {
  width: 25vw;
  overflow: hidden;

  &.fullscreen {
    width: 100%;
  }

  @media #{$breakpoint-down-lg} {
    width: 50vw;
  }
  @media #{$breakpoint-down-md} {
    width: 50vw;
  }
  @media #{$breakpoint-down-sm} {
    width: 75vw;
  }

  .notification-close-btn {
    i {
      font-size: 16px;
    }
  }

  .notification-title {
    font-size: 18px;
    font-weight: normal;
  }

  .notification-list {
    overflow: auto;
    max-height: 70vh;
  }
  .notification-empty {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;

    .notification-empty__img {
      width: 50%;
      margin-bottom: 15px;
    }
  }
}
</style>
